<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string;
    elems: Array<any>;
    modelValue?: string;
    placeholder?: string;
    valid?: boolean;
    error?: string;
    autocomplete?: string | undefined;
    required?: boolean | undefined;
  }>(),
  {
    valid: true,
    error: "",
    modelValue: "",
    placeholder: undefined,
    required: undefined,
    autocomplete: undefined,
  }
);

const emit = defineEmits(["update:modelValue", "input", "change"]);

const updateValue = (event: any) => {
  emit("update:modelValue", event.target.value);
  emit("input");
};
</script>

<template>
  <div>
    <div class="relative w-auto">
      <select
        v-bind="$attrs"
        :id="id"
        :name="id"
        :value="modelValue"
        :autocomplete="autocomplete"
        :required="required"
        class="pr-6 w-full bg-maas-background-default rounded py-2 pl-2.5 border-1 border-maas-border-grey-normal focus:outline-1 focus:outline-maas-border-primary appearance-none"
        :class="{
          'border-red-700 outline-red-700': !props.valid,
          'border-maas-border-grey-normal outline-maas-border-grey-normal': props.valid,
        }"
        @input="updateValue"
        @change="emit('change')"
      >
        <option v-if="placeholder" value="" disabled :selected="modelValue == ''" hidden>{{ placeholder }}</option>
        <option v-for="elem in props.elems" :key="elem.key" :value="elem.key">{{ elem.label }}</option>
      </select>

      <span class="absolute flex items-center px-2 right-0 top-0 bottom-0 z-1 pointer-events-none">
        <svg class="fill-maas-icon-primary" width="8" height="5" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M.293.293A1 1 0 0 1 1.613.21l.094.083L4 2.585 6.293.293A1 1 0 0 1 7.613.21l.094.083a1 1 0 0 1 .083 1.32l-.083.094-3 3a1 1 0 0 1-1.32.083l-.094-.083-3-3a1 1 0 0 1 0-1.414Z" /></svg>
      </span>
    </div>

    <div v-if="!props.valid" class="my-2 text-red-700">{{ props.error }}</div>
  </div>
</template>

<style>
/* TODO: This is chrome specific, placeholder color should be set globally? */
select:invalid { color: #a9a9a9; }
select option { color: black; }
</style>